/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,ViewEncapsulation} from '@angular/core';

//
import {MnMmdb} from './MnMmdb';

@Component({
    selector: 'mn-mmdb-test',
    templateUrl: './MnMmdbTest.html',
    styleUrls: ['./MnMmdbTest.css'],
    encapsulation: ViewEncapsulation.None
})
export class MnMmdbTest {

    tree: Promise<any>|null = null;

    constructor(private mMmdb:MnMmdb) {
        this.tree = this.mMmdb.getModelTree().toPromise();
    }
}
