/**
 * Created by joerg on 7/24/17.
 */


"use strict";

// rxjs
import 'rxjs/Rx';
import {Subject, BehaviorSubject, Subscription, Observable} from 'rxjs/Rx';

// angular2
import {Injectable} from "@angular/core";

// mn
import {MnBackend, MnDeepMerge} from "@mn/core"


@Injectable()
export class MnMmdb {
    //private mRequestSubject:BehaviorSubject< MnHelpPath > = new BehaviorSubject([]);

    constructor(private mBackend:MnBackend) {

        console.log("MMDB")
        this.mBackend.get("/predict/ModelTree/?format=json").map(res => res.json()).subscribe(
            data => {
                console.log("mmdb",data);
            }
        );

    }

    public getModelTree():Observable<any> {
        return this.mBackend.get("/predict/ModelTree/?format=json").map(res => res.json())
    }

    public getModelData(model_id:string):Observable<any> {
        return this.mBackend.get(`/modelmetadb/MmDbPredictiveModel/${model_id}/?format=json`).map(res => res.json())
    }


}