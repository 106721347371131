/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';

//
import { MnMmdb } from './MnMmdb';
import { MnMmdbTest } from './MnMmdbTest';

let directives = [MnMmdbTest];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule
    ],
    exports: [],
    providers: [MnMmdb],
    declarations: directives,
    entryComponents: directives
})
export class MnMmdbModule {
    constructor() {}
    /*static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnHelpModule,
            providers: [MnHelpService]
        };
    }*/
}

export class mn_mmdb {
    static configure():any {
        return [
            MnMmdbModule
            //MnHelpModule.forRoot()
        ];
    }
}